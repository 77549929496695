var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c(
        "avue-skeleton",
        { attrs: { loading: _vm.waiting, avatar: "", rows: 8 } },
        [
          _c("avue-affix", { attrs: { id: "avue-view", "offset-top": 114 } }, [
            _c(
              "div",
              { staticClass: "header" },
              [
                _c("avue-title", {
                  attrs: { value: _vm.process.processDefinitionName },
                }),
              ],
              1
            ),
          ]),
          _c(
            "el-tabs",
            {
              staticStyle: { "background-color": "#FFFFFF", padding: "12px" },
              attrs: { type: "card" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t(`cip.desk.workflow.title.indexHeadFirst`),
                    name: "first",
                  },
                },
                [
                  _c("el-card", { attrs: { shadow: "never" } }, [
                    _c(
                      "div",
                      {
                        class:
                          _vm.process.status != "todo"
                            ? `wf-theme-${_vm.theme}`
                            : "",
                        attrs: { id: "printBody" },
                      },
                      [
                        _vm.option &&
                        ((_vm.option.column && _vm.option.column.length > 0) ||
                          (_vm.option.group && _vm.option.group.length > 0))
                          ? _c("avue-form", {
                              ref: "form",
                              attrs: {
                                defaults: _vm.defaults,
                                option: _vm.option,
                                "upload-preview": _vm.handleUploadPreview,
                              },
                              on: {
                                "update:defaults": function ($event) {
                                  _vm.defaults = $event
                                },
                              },
                              model: {
                                value: _vm.form,
                                callback: function ($$v) {
                                  _vm.form = $$v
                                },
                                expression: "form",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm.process.status == "todo"
                    ? _c(
                        "el-card",
                        {
                          staticStyle: { "margin-top": "20px" },
                          attrs: { shadow: "never" },
                        },
                        [
                          _c("wf-examine-form", {
                            ref: "examineForm",
                            attrs: {
                              comment: _vm.comment,
                              process: _vm.process,
                            },
                            on: {
                              "update:comment": function ($event) {
                                _vm.comment = $event
                              },
                              "user-select": _vm.handleUserSelect,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t(`cip.desk.workflow.title.indexHeadSecond`),
                    name: "second",
                  },
                },
                [
                  _c(
                    "el-card",
                    { attrs: { shadow: "never" } },
                    [_c("wf-flow", { attrs: { flow: _vm.flow } })],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t(`cip.desk.workflow.title.indexHeadThird`),
                    name: "third",
                  },
                },
                [
                  _vm.activeName == "third"
                    ? [
                        _c(
                          "el-card",
                          { attrs: { shadow: "never" } },
                          [
                            _c("wf-design", {
                              ref: "bpmn",
                              staticStyle: { height: "500px" },
                              attrs: { options: _vm.bpmnOption },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("wf-button", {
        attrs: {
          loading: _vm.submitLoading,
          "button-list": _vm.buttonList,
          process: _vm.process,
          comment: _vm.comment,
        },
        on: {
          examine: _vm.handleExamine,
          "user-select": _vm.handleUserSelect,
          print: _vm.handlePrint,
          rollback: _vm.handleRollbackTask,
          terminate: _vm.handleTerminateProcess,
          withdraw: _vm.handleWithdrawTask,
        },
      }),
      _c("user-select", {
        ref: "user-select",
        attrs: {
          "check-type": _vm.checkType,
          "default-checked": _vm.defaultChecked,
        },
        on: { onConfirm: _vm.handleUserSelectConfirm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }